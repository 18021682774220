<template>
  <v-footer dark padless class="fm-footer">
    <v-container>
        <v-row>
            <v-col class="col-12 col-lg-7">
                <v-row>
                    <v-col class="col-12 col-sm-4">
                        <v-subheader>Products:</v-subheader>
                        <v-list-item-group v-model="products" color="primary">
                            <v-list-item class="move-to" to="/" data-section="high-speed"><span>Fibre</span></v-list-item>
                            <v-list-item href="https://www.vumacam.co.za/" target="_blank"><span>Cameras</span></v-list-item>
                            <!-- <v-list-item><span>Solar</span></v-list-item> -->
                        </v-list-item-group>
                    </v-col>
                    <v-col class="col-12 col-sm-4">
                        <v-subheader>Links:</v-subheader>
                        <v-list-item-group v-model="links" color="primary">
                            <v-list-item to="/"><span>Home</span></v-list-item>
                            <v-list-item class="move-to" to="/" data-section="vm-coverage-wrap"><span>Check your coverage</span></v-list-item>
                            <v-list-item class="move-to" to="/" data-section="high-speed"><span>What is fibre?</span></v-list-item>
                            <v-list-item class="move-to" to="/" data-section="vuma-benefit"><span>Why do I need fibre?</span></v-list-item>
                            <!-- <v-list-item class="move-to" to="/" data-section="vuma-benefit"><span>The fibre process</span></v-list-item> -->
                            <v-list-item to="/careers"><span>Careers</span></v-list-item>
                            <v-list-item to="/news"><span>News</span></v-list-item>
                            <v-list-item to="/network-status"><span>Network status</span></v-list-item>
                            <v-list-item to="/faqs"><span>Help</span></v-list-item>
                            <v-list-item to="/contact-us"><span>Contact us</span></v-list-item>
                        </v-list-item-group>
                    </v-col>
                    <v-col class="col-12 col-sm-4">
                        <v-subheader>Legal:</v-subheader>
                        <v-list-item-group v-model="legal" color="primary">
                            <v-list-item href="https://cdn.vumatel.co.za/files/VUMA-CODE-OF-CONDUCT-COMPLAINTS-PROCEDURES.pdf" target="_blank">
                                <span>Code of conduct</span>
                            </v-list-item>
                            <v-list-item href="https://cdn.vumatel.co.za/files/VUMA-CODE-OF-CONDUCT-COMPLAINTS-PROCEDURES.pdf" target="_blank">
                                <span>Complaints procedure</span>
                            </v-list-item>
                            <v-list-item href="https://cdn.vumatel.co.za/files/VUMA-HEALTH-SAFTEY-POLICY.pdf" target="_blank">
                                <span>Health &amp; safety policy</span>
                            </v-list-item>
                            <v-list-item :href="termsAndConditionsUrl" target="_blank">
                                <span>Terms &amp; conditions</span>
                            </v-list-item>
                            <v-list-item href="https://cdn.vumatel.co.za/files/vumatel-paia-popi-manual.pdf" target="_blank">
                                <span>PAIA POPI manual</span>
                            </v-list-item>
                            <v-list-item href="https://cdn.vumatel.co.za/files/vumatel-privacy-policy.pdf" target="_blank">
                                <span>Privacy Policy</span>
                            </v-list-item>
                            <v-list-item href="https://cdn.vumatel.co.za/files/bmit-terms-and-conditions.pdf" target="_blank">
                                <span>Survey Terms & Conditions</span>
                            </v-list-item>
                        </v-list-item-group>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="col-12 col-lg-5">
                <div class="vm-actions d-flex align-stretch justify-end">
                    <div class="vm-inner-actions d-flex align-end">
                        <div class="vm-inner-actions-wrap text-right">
                            <div class="vm-footer-icons">
                                <v-btn
                                  v-for="icon in icons"
                                  class="mx-3 white--text"
                                  icon
                                  :href="icon.link"
                                  target="_blank"
                                >
                                  <v-icon size="36px">{{ icon.icon }}</v-icon>
                                </v-btn>    
                            </div>
                            
                            <v-btn class="move-to f-btn" to="/" data-section="vm-coverage-wrap" rounded outlined>
                                Check my coverage
                            </v-btn>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col class="col-12 text-center">
                <v-divider></v-divider>
                <div class="vm-copyright">
                    Vumatel {{ new Date().getFullYear() }} 
                    — All Rights Reserved. 
                    <!-- — <a href="https://cdn.vumatel.co.za/files/VUMA-Installations-Terms-Conditions.pdf" target="_blank">Terms & Conditions</a> -->
                </div>
            </v-col>
        </v-row>
    </v-container>

    
  </v-footer>
</template>

<script>
  export default {
    name: 'Footer',
    data: () => ({
        products: '',
        links: '',
        legal: '',
      articles: [
        {
          title: 'Notice of merger between CIVH and Vumatel',
          date: '01 April 2020',
          src: 'featured-1.jpg',
          href: '#',
        },
        {
          title: 'Franklin D Roosevelt Primary School gets connected to Vuma',
          date: '15 March 2020',
          src: 'featured-1.jpg',
          href: '#',
        },
        {
          title: 'Vuma Schools project - St Vincent School for the Deaf',
          date: '01 March 2020',
          src: 'featured-1.jpg',
          href: '#',
        },
      ],
      icons: [
          {
            'icon': 'mdi-facebook',
            'link': 'https://www.facebook.com/profile.php?id=100064644344060'
          },
          {
            'icon': 'mdi-twitter',
            'link': 'https://twitter.com/vumatel'
          },
          {
            'icon': 'mdi-linkedin',
            'link': 'https://www.linkedin.com/company/vumatel/'
          }
      ],
    }),
    mounted() {
        $('.move-to').on('click', function (e) {
            e.preventDefault();

            // var target = this.hash;
            var target = '#' + $(this).data('section');
            var $target = $(target);

            $('html, body').stop().animate({
                'scrollTop': $target.offset().top - 70
            }, 900, 'swing');
        });
    },
    computed: {
        termsAndConditionsUrl() {
            const routePath = this.$route?.path?.toLowerCase() || "";
            return routePath.includes('reach') ? 
                'https://cdn.vumatel.co.za/files/VUMA%20Reach%20-%20National%20Installation%20Standard.pdf' : 
                'https://cdn.vumatel.co.za/files/VUMA%20Core%20-%20National%20Installation%20Standard.pdf'
        }
    }
  }
</script>

<style lang="scss">
    .fm-footer{
        font-size: 20px;
        &.theme--dark { background-color: #17183B !important; }
        // NEST
        .vm-actions{
            height: 100%;
        }
        .vm-footer-icons{
            margin-bottom: 1rem;
            // NEST
            .v-btn--icon {
                height: 36px !important;
                padding: 0 !important;
                &:hover{
                    color: #ee008c !important;
                    border-color: transparent;
                    background-color: transparent;;
                }
            }
        }
        .f-btn{
            &:before{ opacity: 0; }
            &:hover{
                color: #fff;
                border-color: #ee008c;
                background-color: #ee008c;
                // &:before{ opacity: 1 !important; }
            }
        }
        .theme--dark.v-subheader {
            color: #fff;
            font-size: 20px;
            font-family: "Core Sans R 45", "Roboto", sans-serif !important;
            letter-spacing: 1px;
        }
        .v-list-item{
            min-height: 34px;
            &.v-list-item--active{
                color: #fff;
                &::before{
                    background-color: transparent;
                    opacity: 1 !important;
                }
                // NEST
                span{
                    position: relative;
                    z-index: 2;
                }
            }
            &:hover{
                color: #fff;
                &::before{
                    background-color: #ee008c;
                    opacity: 1 !important;
                }
                span{
                    position: relative;
                    z-index: 2;
                }
            }
        }
        .vm-copyright{
            margin-top: 1rem;
            // NEST
            a{
                color: #fff;
                text-decoration: none;
            }
        }
        // .container { max-width: 1580px; }

        .v-ripple__container { display:none !important; }
        
    }
</style>